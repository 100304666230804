.input-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    height: 56px;
    border-radius: 100px;
    background: #F2FAFF;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}
.input-switch svg {
    width: 28px;
    height: 28px;
}
.input-switch svg path {
    transition: fill 0.3s ease-in-out;
}
.input-switch:hover {
    background: #2E7FF9;
}
.input-switch:hover svg path {
    fill: #FFFFFF;
}