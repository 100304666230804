.control {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
}
.control .control-container {
    display: flex;
    justify-content: space-between;
}
.control .control-container.text {
    align-items: center;
}
.control .control-container.voice {
    align-items: flex-end;
}
.control .control-container p {
    color: #5A9CFF;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}