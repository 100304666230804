@keyframes voice-output {
    0% {
        transform: rotate(-45deg);
    }
    25% {
        transform: rotate(-180deg);
    }
    50% {
        transform: rotate(120deg);
    }
    75% {
        transform: rotate(-90deg);
    }
    100% {
        transform: rotate(-45deg);
    }
}
.voice-output {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.voice-output .outer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 200px;
    height: 200px;
    background: linear-gradient(180deg, #E7F6FF 0%, #D7E7FF 100%);
    border-radius: 100%;
    filter: blur(10px);
    animation: voice-output 3s linear infinite;
}
.voice-output .inner {
    width: 100px;
    height: 100px;
    background: linear-gradient(180deg, #E7F6FF 0%, #D7E7FF 100%);
    border-radius: 100%;
    filter: blur(10px);
}
.voice-output.processing .outer {
    background: linear-gradient(143.15deg, #C5E9FF 8.37%, #BEFFEC 91.49%);
}
.voice-output.speaking .outer {
    background: linear-gradient(143.15deg, #CDECFF 8.37%, #F7ECFF 91.49%);
}
