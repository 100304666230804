.text-input {
    margin-top: 8px;
    width: 100%;
}
.text-input input {
    padding: 0 16px;
    width: 100%;
    height: 56px;
    border: 1px solid #AADAF7;
    border-radius: 50px;
    color: #525252;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    outline: none;
}
.text-input input:disabled {
    border: 1px solid #D3D3D3;
    background: transparent;
}