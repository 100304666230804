.voice-input,
.voice-input p,
.voice-input div,
.voice-input div > div,
.voice-input div > span,
.voice-input div > svg {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
}
.voice-input {
    display: flex;
    flex-direction: column;
    align-items: center;
}
.voice-input p {
    margin-bottom: 8px;
    height: 19px;
    color: #5A9CFF;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}
.voice-input div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 112px;
    height: 112px;
    background: #F2FAFF;
    border-radius: 100%;
    cursor: pointer;
}
.voice-input div svg {
    width: 32px;
    height: 32px;
}
.voice-input .listen div div {
    margin-top: 16px;
    margin-bottom: 6px;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    background: #FD7A7A;
}
.voice-input .waiting,
.voice-input .processing,
.voice-input .speaking {
    background: #F1F1F1;
    pointer-events: none;
}
.voice-input .waiting svg path,
.voice-input .processing svg path,
.voice-input .speaking svg path {
    fill: #C6C6C6;
}