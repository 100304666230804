.text-output {
    display: flex;
    flex-direction: column;
    padding-right: 8px;
    margin-top: auto;
    margin-bottom: 20px;
    width: 100%;
    max-height: 100%;
    overflow-y: scroll;
}
.text-output .text {
    display: flex;
    align-items: flex-end;
    padding: 8px 12px;
    margin-bottom: 24px;
    width: fit-content;
    overflow-wrap: anywhere;
    white-space: pre-wrap;
}
.text-output .text:last-child {
    margin-bottom: 0;
}
.text-output .text.user {
    margin-left: auto;
    border: 1px solid #BDD8FF;
    border-radius: 8px 8px 0 8px;
}
.text-output .text.bot {
    margin-right: auto;
    border-radius: 8px 8px 8px 0;
    background: #E7F6FF;
}
.text-output .text div {
    display: flex;
    margin-left: 8px;
    cursor: pointer;
}