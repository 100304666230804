* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
::-webkit-scrollbar {
    width: 8px;
}
::-webkit-scrollbar-track {
    background: #FFFFFF;

}
::-webkit-scrollbar-thumb {
    background: #EAF5FC;
    border-radius: 8px;
}
.app {
    display: flex;
    justify-content: center;
    height: 100svh;
    padding: 100px 24px 50px 24px;
}
.app .container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 600px;
    height: 100%;
}