.notification-container {
    position: fixed;
    top: -100px;
    display: flex;
    justify-content: center;
    width: 100%;
    transition: top 0.3s ease-in-out;
}
.notification-container.visible {
    top: 24px;
}
.notification-container .notification {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 14px 16px;
    width: 100%;
    max-width: 600px;
    background: #322F35;
    border-radius: 4px;
    box-shadow: 0 4px 8px 3px #00000026;
}
.notification-container .notification span {
    color: #FFFFFF;
    font-family: "Noto Sans", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 19px;
}
.notification-container .notification svg {
    cursor: pointer;
}
.notification-container .notification svg path {
    fill: #FFFFFF;
}