.output-switch {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 56px;
    height: 56px;
    border-radius: 100%;
    background: #F2FAFF;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}
.output-switch svg path {
    fill: #2E7FF9;
    transition: fill 0.3s ease-in-out;
}
.output-switch:hover {
    background: #2E7FF9;
}
.output-switch:hover > svg path {
    fill: #FFFFFF;
}
.output-switch.active {
    background: #2E7FF9;
}
.output-switch.active > svg path {
    fill: #FFFFFF;
}

.output-switch .output-switch-menu {
    position: absolute;
    top: -216px;
    display: flex;
    flex-direction: column;
}
.output-switch .output-switch-menu div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 16px;
    width: 56px;
    height: 56px;
    background: #F2FAFF;
    border-radius: 100%;
    transition: background-color 0.3s ease-in-out;
    cursor: pointer;
}
.output-switch .output-switch-menu div:hover {
    background: #2E7FF9;
}
.output-switch .output-switch-menu div:hover svg path {
    fill: #FFFFFF;
}
.output-switch .output-switch-menu div.active {
    background: #BBD6FF;
}
.output-switch .output-switch-menu div.active svg path {
    fill: #2E7FF9;
}